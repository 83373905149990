import "./footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Meron </h1>
        <ul className="footer__list">
          <li>
            <a href="#about" className="footer__link">
              About
            </a>
          </li>
          <li>
            <a href="#portfolio" className="footer__link">
              Projects
            </a>
          </li>
          <li>
            <a href="#testimonials" className="footer__link">
              Testimonials
            </a>
          </li>
        </ul>
        <div className="footer__social">
          <a
            href="https://linkdin.com/in/Meron-legese"
            rel="noreferrer"
            className="footer__social-icon"
            target="_blank"
          >
            <i style={{ color: "blue" }} className="bx bxl-linkedin"></i>
          </a>
          <a
            href="https://gitlab.com/Meron12"
            rel="noreferrer"
            className="footer__social-icon"
            target="_blank"
          >
            <i style={{ color: "orange" }} className="bx bxl-gitlab"></i>
          </a>
          <a
            href="https://github.com/Meronlegese"
            rel="noreferrer"
            className="footer__social-icon"
            target="_blank"
          >
            <i className="bx bxl-github"></i>
          </a>
        </div>
        <span className="footer__copy">
          {" "}
          {currentYear} &#169; Meron Gemechu. All rights reserved.{" "}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
