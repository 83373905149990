const DigitalMarketing = () => {
  return (
    <div className="skills__content">
      <h3
        className="skills__title"
        style={{ color: "green", fontWeight: "bold" }}
      >
        Digital Marketing Skills
      </h3>
      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i style={{ color: "green" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Social Media Marketing</h3>
            </div>
          </div>

          <div className="skills__data">
            <i style={{ color: "green" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Content Marketing</h3>
            </div>
          </div>
          <div className="skills__data">
            <i style={{ color: "green" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Analytics and Reporting</h3>
            </div>
          </div>
        </div>

        <div className="skills__group">
          <div className="skills__data">
            <i style={{ color: "green" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Email Marketing</h3>
            </div>
          </div>

          <div className="skills__data">
            <i style={{ color: "green" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Pay-Per-Click Ad. </h3>
            </div>
          </div>

          <div className="skills__data">
            <i style={{ color: "green" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Search Engine Optimization</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketing;
