const Accounting = () => {
  return (
    <div className="skills__content">
      <h3
        className="skills__title"
        style={{ color: "blue", fontWeight: "bold" }}
      >
        Accounting Skills
      </h3>
      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i style={{ color: "blue" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Financial Analysis</h3>
            </div>
          </div>

          <div className="skills__data">
            <i style={{ color: "blue" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Budgeting</h3>
            </div>
          </div>

          <div className="skills__data">
            <i style={{ color: "blue" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Financial Reporting</h3>
            </div>
          </div>
        </div>

        <div className="skills__group">
          <div className="skills__data">
            <i style={{ color: "blue" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Taxation</h3>
            </div>
          </div>

          <div className="skills__data">
            <i style={{ color: "blue" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Auditing</h3>
            </div>
          </div>

          <div className="skills__data">
            <i style={{ color: "blue" }} className="bx bx-badge-check"></i>
            <div>
              <h3 className="skills__name">Financial Planning</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounting;
