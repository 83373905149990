import "./skill.css";
import Accounting from "./Web";
import DigitalMarketing from "./Cloud";
// import DevOps from "./DevOps";

const Skills = () => {
  return (
    <section className="skills section" id="skills">
      <h3 className="section__title"> Skills </h3>
      <span className="section__subtitle"> My Technical Level </span>

      <div className="skills__container container grid">
        <Accounting />
        <DigitalMarketing />
      </div>
    </section>
  );
};

export default Skills;
